/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { AnimationItem } from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import { animationData } from '../animations/animation-data';
import useLayoutEffect from '../hooks/useIsomorphicLayoutEffect';
import { Burger } from './Burger';
import Logo from './Logo';
import { useActiveScrollspy } from './ScrollSpyContext';
import debounce from 'lodash.debounce';

const Navbar = () => {
  const hamburgerRef = useRef<HTMLButtonElement>(null);
  const mobileMenu = useRef<HTMLDivElement>(null);
  const [anim, setAnim] = useState<AnimationItem | null>(null);
  const [isMainPage, setIsMainPage] = useState<boolean | null>(null);
  const [partentX, setParentX] = useState<number>(0);
  const [overlay, setOverlay] = useState({ o: 0, x: 500, w: 0 });

  const location = useLocation();
  const { setIgnore, ignore } = useActiveScrollspy();

  // check if main page on route change
  useEffect(() => setIsMainPage(location.pathname === '/'), [location.pathname]);

  const [navbarIsTransparent, setNavbarIsTransparent] = useState(false);

  const handleNavbarColorOnScroll = () => {
    const position = window.scrollY;
    if (position >= 120) {
      setNavbarIsTransparent(true);
    } else {
      setNavbarIsTransparent(false);
    }
  };

  useEffect(() => {
    let lottie: any = null;
    const dynamicLottie = async () => {
      lottie = await import('lottie-web');
      const params = {
        name: 'mobileMenu',
        container: mobileMenu.current!,
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'none',
        },
      };

      const animation = lottie.loadAnimation(params);
      setAnim(() => animation);
    };

    dynamicLottie();
    window.addEventListener('scroll', handleNavbarColorOnScroll, true);

    // initially set nav items container left position for overlay absolute positioning
    setParentX(document.querySelector('#navbar .desktop-only')?.getBoundingClientRect().left || 0);

    // update nav items container left position and inner absolute overlay position on resize
    const updateValue = debounce(() => {
      if (window.innerWidth < 768) return;
      setParentX((prev) => {
        const newX =
          document.querySelector('#navbar .desktop-only')?.getBoundingClientRect().left || 0;
        const difference = prev - newX;
        setOverlay((prevInner) => ({ ...prevInner, x: prevInner.x - difference }));

        return prev - difference;
      });
    }, 500);

    const handleResize = () => {
      updateValue();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleNavbarColorOnScroll);
      lottie?.destroy('mobileMenu');
    };
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        '.desktop-only .nav-link, .logo',
        {
          opacity: 0,
          y: -40,
        },
        { opacity: 1, stagger: 0.1, duration: 1, y: 0 }
      );
    }, '#navbar');

    return () => ctx.revert();
  }, []);

  // set overlay position on scroll spy active element change
  const { activeElement } = useActiveScrollspy();
  useEffect(() => {
    if (activeElement && !ignore) {
      setOverlay({
        o: 1,
        x: activeElement.getBoundingClientRect().left,
        w: activeElement.getBoundingClientRect().width,
      });
    }
  }, [activeElement, ignore]);

  const commonNavLinkProps = {
    className: 'nav-link',
    // set overlay position on hover
    onMouseEnter: (e: any) => {
      setOverlay({
        o: 1,
        x: e.target.getBoundingClientRect().left,
        w: e.target.getBoundingClientRect().width,
      });
    },
    // reset overlay position to active element
    onMouseLeave: () => {
      if (ignore) return;
      const el = document.querySelector('#navbar .active') as HTMLElement;
      if (el) {
        setOverlay({
          o: 1,
          x: el.getBoundingClientRect().left,
          w: el.getBoundingClientRect().width,
        });
      } else {
        setOverlay((prev) => ({ ...prev, o: 0 }));
      }
    },
    // set ignore to prevent overlay position change on every scroll spy change
    // e.g. when autoscroll triggers multiple scroll spy sections
    onClick: () => {
      setIgnore(true);
      setTimeout(() => {
        setIgnore(false);
      }, 1000);
    },
  };

  const handleHamburgerClick = () => {
    if (hamburgerRef.current && mobileMenu.current) {
      hamburgerRef.current.classList.toggle('is-active');
      mobileMenu.current.classList.toggle('is-active');
      document.querySelector('.mobile-logo')?.classList.toggle('is-active');

      if (mobileMenu.current.classList.contains('is-active')) {
        anim?.setDirection(1);
        anim?.play('mobileMenu');

        if (typeof document !== undefined) {
          document.querySelector('html')!.style.overflow = 'hidden';
        }
      } else {
        anim?.setDirection(-1);
        anim?.play('mobileMenu');

        if (typeof document !== undefined) {
          document.querySelector('html')!.style.overflow = 'unset';
        }
      }
    }
  };

  return (
    <nav id='navbar' className={[isMainPage ? 'is-sticky' : ''].join(' ')}>
      <div className='row'>
        <div
          className={['nav-background-filter', !navbarIsTransparent ? 'is-on-top' : ''].join(' ')}
        />
        <Link className='logo' to='/' aria-label='Link zur Homepage' data-scroll='#hero'>
          <Logo width={60} animated />
        </Link>
        <div id='' className='desktop-only' style={{ position: 'relative' }}>
          <div
            className='overlay'
            style={{
              left: overlay.x - (partentX || 0) + 'px',
              width: overlay.w + 'px',
              opacity: overlay.o,
            }}
          />
          <div data-to-scrollspy-id='hero' />
          <div className='nav-links'>
            <div data-to-scrollspy-id='about' {...commonNavLinkProps}>
              <Link className='al' to='/#about' data-scroll={'#about'}>
                About
              </Link>
            </div>

            <div data-to-scrollspy-id='services' {...commonNavLinkProps}>
              <Link className='al' to='/#services' data-scroll={'#services'}>
                Tätigkeit
              </Link>
            </div>

            <div data-to-scrollspy-id='gallery' {...commonNavLinkProps}>
              <Link className='al' to='/#gallery' data-scroll={'#gallery'}>
                Galerie
              </Link>
            </div>

            <div data-to-scrollspy-id='contact' {...commonNavLinkProps}>
              <Link className='al' to='/#contact' data-scroll={'#contact'}>
                Kontakt
              </Link>
            </div>
          </div>
        </div>
        <div className='mobile-nav-wrapper mobile-only'>
          <button
            id='hamburger'
            aria-label='burger-menu'
            ref={hamburgerRef}
            onClick={() => handleHamburgerClick()}
          >
            <Burger />
          </button>

          <div className='mobile-nav' ref={mobileMenu}>
            <div className='nav-links'>
              <div
                tabIndex={-1}
                data-to-scrollspy-id='about'
                className='nav-link'
                onClick={() => handleHamburgerClick()}
              >
                <Link to='/#about' data-scroll='#about'>
                  About
                </Link>
              </div>

              <div
                tabIndex={-1}
                data-to-scrollspy-id='services'
                className='nav-link'
                onClick={() => handleHamburgerClick()}
              >
                <Link to='/#services' data-scroll='#services'>
                  Tätigkeit
                </Link>
              </div>
              <div
                tabIndex={-1}
                data-to-scrollspy-id='gallery'
                className='nav-link'
                onClick={() => handleHamburgerClick()}
              >
                <Link to='/#gallery' data-scroll='#gallery'>
                  Galerie
                </Link>
              </div>
              <div
                tabIndex={-1}
                data-to-scrollspy-id='contact'
                className='nav-link'
                onClick={() => handleHamburgerClick()}
              >
                <Link to='/#contact' data-scroll='#contact'>
                  Kontakt
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
